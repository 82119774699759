import React from "react"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { Carousel } from "react-bootstrap"
import CompSol from "../components/compsol"

import "../scss/home.scss"

import RobotHero from "../assets/img/youpalGroup/robotHero.png"
import BgBug from "../assets/img/youpalGroup/background.png"
import Partner1 from "../assets/img/youpalGroup/homepage/partner1.svg"
import Partner2 from "../assets/img/youpalGroup/homepage/partner2.svg"
import Partner3 from "../assets/img/youpalGroup/homepage/partner3.svg"
import Partner4 from "../assets/img/youpalGroup/homepage/partner4.svg"
import Partner5 from "../assets/img/youpalGroup/homepage/partner5.png"
import Partner6 from "../assets/img/youpalGroup/homepage/partner5.svg"
import Slide1 from "../assets/img/youpalGroup/homepage/slide1.png"
import Slide2 from "../assets/img/youpalGroup/homepage/slide2.png"
import People1 from "../assets/img/youpalGroup/homepage/people1.png"
import People2 from "../assets/img/youpalGroup/homepage/people2.jpg"
import People3 from "../assets/img/youpalGroup/homepage/people3.jpeg"
import People4 from "../assets/img/youpalGroup/homepage/people4.jpg"
import People5 from "../assets/img/youpalGroup/homepage/people5.jpeg"
import People6 from "../assets/img/youpalGroup/homepage/people6.jpg"
import RobotImage from "../assets/img/youpalGroup/homepage/robot2.png"
import News from "../components/news"

const IndexPage = () => {
  return (
    <>
      <div className="bgBug">
        <img src={BgBug} />
      </div>
      <SEO title="Home" keywords={[`youpal`, `youpal group`]} />
      <div
        className="homeMain"
        data-spy="scroll"
        data-target="#sideNavbar"
        data-offset="0"
      >
        <div className="scrollSpyComponent">
          <nav id="sideNavbar" className="col">
            <a className="nav-link" href="#Home">
              <div className="sideDotDiv row">
                <div className="tooltipDiv">01 Home</div>
                <div className="sideDot"></div>
              </div>
            </a>
            <a className="nav-link" href="#Companies">
              <div className="sideDotDiv row">
                <div className="tooltipDiv">02 Companies</div>
                <div className="sideDot"></div>
              </div>
            </a>
            <a className="nav-link" href="#News">
              <div className="sideDotDiv row">
                <div className="tooltipDiv">03 News</div>
                <div className="sideDot"></div>
              </div>
            </a>
            <a className="nav-link" href="#Partners">
              <div className="sideDotDiv row">
                <div className="tooltipDiv">04 Partners</div>
                <div className="sideDot"></div>
              </div>
            </a>
            <a className="nav-link" href="#Our-Stories">
              <div className="sideDotDiv row">
                <div className="tooltipDiv">05 Our Stories</div>
                <div className="sideDot"></div>
              </div>
            </a>
            <a className="nav-link" href="#Testimonials">
              <div className="sideDotDiv row">
                <div className="tooltipDiv">06 Testimonials</div>
                <div className="sideDot"></div>
              </div>
            </a>
            <a className="nav-link" href="#YouBlog">
              <div className="sideDotDiv row">
                <div className="tooltipDiv">07 YouBlog</div>
                <div className="sideDot"></div>
              </div>
            </a>
          </nav>
        </div>
        <section id="Home" className="container hero">
          <div className="row">
            <div className="col">
              <div className="col sideContent">
                <p>
                Youpal Group is focused on making the products that are relevant both today and tomorrow. Our endeavour is to design the best digital infrastructure roadmap solutions. As we see it, the sky is the limit. And for that, we build upon a cloud.
                </p>
                <p className="secondaryParagraph">
                Youpal Group builds, owns and invests in the foundation of a variety of companies. The industries in which in operate include med-tech, ed-tech and a plethora of other services and solutions. We own a cloud, we dream upon this cloud and build upon it continuously.
                </p>
                <p style={{ marginTop: "40px" }}>
                  <Link to="/about-us">
                    <button type="button" className="px-4 btn btn-primary">
                      Read More
                    </button>
                  </Link>
                </p>
              </div>
            </div>
            <div className="col heroCol">
              <img className="sideImg" src={RobotHero} />
            </div>
          </div>
        </section>

        <section id="Companies" className="container">
          <div className="col-lg">
            <hr />
            <h2>Companies & Solutions</h2>
            <CompSol />
          </div>
        </section>

        <section id="News" className="container latestNews">
          <News />
        </section>

        <section id="Partners" className="container ourPartners">
          <div className="col-lg">
            <hr />
            <h2>Our Clients</h2>

            <div className="row partnersRow">
              <img className="owlImg" src={Partner6} />
              <img className="owlImg" src={Partner5} />
              <img className="owlImg" src={Partner3} />
              <img className="owlImg" src={Partner4} />
              <img className="owlImg" src={Partner1} />
              <img className="owlImg" src={Partner2} />
            </div>
          </div>
        </section>

        <section className="container">
          <Carousel>
            <Carousel.Item>
              <img className="d-block w-100" src={Slide1} alt="First slide" />
              <Carousel.Caption>
                <h3>
                  Technology is not only the evolution of history, but also the
                  evolution of thoughts
                </h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className="d-block w-100" src={Slide2} alt="Second slide" />
              <Carousel.Caption>
                <h3>
                  We push the boundaries of technology to drive performance to
                  new levels.
                </h3>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </section>

        <section id="Our-Stories" className="container ourStories">
          <div className="col-lg">
            <hr />
            <div className="textContainer">
              <span>
              Our main story began back in 2016 when the company was founded by Karl Leahlander and Ruben Teijeiro. The pair was later joined by James Baker-Duly. It all began with an idea, which then turned into the goal, which then transformed into the company. We’ve since grown into a team of people who are involved in the digital infrastructure change enabling process.
              </span>
            </div>

            <Link to="/our-stories" className="d-none">
              <button type="button" className="btn btn-primary btn-lg">
                Discover Your Story
              </button>
            </Link>
          </div>
        </section>

        <section id="Testimonials" className="container whatPeopleSay">
          <div className="col-lg">
            <h2>What people say about us</h2>
            <p>
              "I am because You are" and what You Think, is very important to Us
            </p>
            <div className="row peopleQuotes">
              <div className="col-md-4 quote">
                <div>
                  <img src={People1} />
                </div>
                <div className="quoteText">
                  “Working at Youpal Group is much more than just a job I go to
                  everyday. My coworkers inspire and motivate me daily.”
                </div>
                <div className="quotePerson">
                  Jessica Landin
                  <br />
                  Executive Assistant to CEO
                  <br />
                  Sweden / Europe
                </div>
              </div>
              <div className="col-md-4 quote">
                <div className="quoteImg">
                  <img src={People2} />
                </div>
                <div className="quoteText">
                  “At Youpal, I travel to different countries through each virtual meeting. And travel is what I love most!”
                </div>
                <div className="quotePerson">
                  Arabella Seebaluck
                  <br />
                  Marketing Communications Lead
                  <br />
                  India / Asia
                </div>
              </div>
              <div className="col-md-4 quote">
                <div className="quoteImg">
                  <img src={People3} />
                </div>
                <div className="quoteText">
                  “In my free time, I like to be upside down! Youpal lets me do that, plus so much more!”
                </div>
                <div className="quotePerson">
                  Sally Ragab
                  <br />
                  Project Warrior
                  <br />
                  Egypt / Africa
                </div>
              </div>
              <div className="col-md-4 quote">
                <div className="quoteImg">
                  <img src={People5} />
                </div>
                <div className="quoteText">
                  “The things we can do with a large enough dataset is truly amazing and really inspires my imagination of how the world will look in just 5 years. This is what YouPal allows me to do, work with a lot of data and stay inspired!”
                </div>
                <div className="quotePerson">
                  Apostolis Argatzopoulos (Tolis)
                  <br />
                  Youdata Team Lead
                  <br />
                  Greece / Europe
                </div>
              </div>
              <div className="col-md-4 quote">
                <div className="quoteImg">
                  <img src={People6} />
                </div>
                <div className="quoteText">
                  “Youpal means hours of creativity and innovation… the power to introduce new ideas and unleash the potential of people and products.”
                </div>
                <div className="quotePerson">
                  Herson Bugante
                  <br />
                  IT Service Manager
                  <br />
                  Philippines / Oceania
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="YouBlog" className="container youBlogSection row">
          <div className="robotImage">
            <img src={RobotImage} />
          </div>
          <div className="blogContent col">
            <hr />
            <h2>YouBlog</h2>
            <p>
              Did you know that 90% of existing data, has been created in the
              past three years alone? According to research, massive centers
              have been developed by industry giants like Google, Facebook, and
              some more, that contribute the most to the extremely overwhelming
              volume of data produced.
            </p>
            <p>Are you tech-curious? Find out more in our blog</p>
            <div className="text-right youBlogButton">
              <Link to="/blog">
                <button type="button" className="btn btn-primary btn-lg">
                  Discover more
                </button>
              </Link>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default IndexPage
