import React, { Component } from "react"

import "../scss/news.scss"

import NotFoundImage from "../assets/img/no-image-found.png"

class News extends Component {

    constructor(props) {
        super(props)

        this.state = {
            posts: [],
            imgFailed: Array(6).fill(false)
        }
    }

    /*

    https://uteckie.com/news/us-military-tests-wearable-ai-combination-as-early-warning-system-for-covid-19-cases/


https://uteckie.com/news/how-to-actively-build-and-maintain-culture-in-a-remote-workplace/


https://uteckie.com/news/survey-fourth-graders-more-tech-savvy-than-their-parents-on-average/



https://uteckie.com/news/holiday-tech-gifts-for-under-25/



https://uteckie.com/news/google-announces-hum-to-search-machine-learning-music-search-feature/



https://uteckie.com/news/how-to-make-data-analytics-work-for-you/

    */

    fallback(id) {
        const {imgFailed} = this.state

        imgFailed[id] = true
        this.setState({
            imgFailed: imgFailed
        })
    }

    componentDidMount() {
        this.fetchPosts();
    }

    fetchPosts() {
        fetch("https://uteckie.com/wp-json/wp/v2/categories")
        .then((res) => res.json())
        .then((res) => {
            const category = res.filter((cat) => cat.slug === 'news')[0].id
            
            if (category) {
                fetch(`https://uteckie.com/wp-json/wp/v2/posts?_embed&categories[]=${category}&page=1&per_page=6`)
                .then((result) => result.json())
                .then((result) => {
                    result = result.map((post) => {
                        return {
                            image: post._embedded['wp:featuredmedia'][0].source_url,
                            title: post.title.rendered,
                            excerpt: post.excerpt.rendered,
                            link: post.link
                        };
                    });

                    this.setState({
                        posts: result
                    });
                })
                .catch((err) => console.log(err));
            }
        })
        .catch((err) => console.log(err));
    }

    render () {
        const {posts, imgFailed} = this.state;

        if (posts.length) {
            return (
                <div className="col-lg">
                    <hr />
                    <h2>Latest News</h2>
                    <div className="row justify-content-center">
                        {
                            posts.map((post, index) => (
                                <div key={"news-" + index} className="latestNewsItem col-lg-4">
                                    <div>
                                        {
                                            (imgFailed[index]) ?
                                                (<img className="recImg" src={NotFoundImage} />)
                                            :
                                            (<img className="recImg" src={post.image} onError={(e) => this.fallback(index)} />)
                                        }
                                        <div className="absoluteCaption">
                                            <span>News</span>
                                        </div>
                                        <h5><a href={post.link} target="_blank">{post.title}</a></h5>
                                        <span className="latestNewsDescription" dangerouslySetInnerHTML={{__html: post.excerpt}}></span>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            )
        }
        else {
            return (<></>)
        }
    }

}

export default News
  