import React, { Component } from "react"
import { navigate } from "gatsby"

import PlanktonComp from "../assets/img/youpalGroup/planktonLogo.png"
import YougigComp from "../assets/img/youpalGroup/yougigLogo.png"


class Navigation extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        const {smallScreen, activeCategory, setCategory, categories} = this.props

        const styles = {
            column: {
                minWidth: '120px',
                maxWidth: smallScreen ? '' : '120px',
                marginTop: smallScreen ? '20px' : '50px',
                order: smallScreen ? 2 : 1
            },
            nav: {
                flexWrap: 'nowrap',
                flexDirection: smallScreen ? 'row' : 'column',
                justifyContent: smallScreen ? 'start' : 'center',
                overflowX: smallScreen ? 'auto' : 'unset',
                textAlign: 'left' 
            },
            link: {
                fontSize: '16px',
                color: '#6a6a6a',
                borderLeft: smallScreen ? 'none' : '4px solid #e2e2ea',
                lineHeight: '27px',
                cursor: 'pointer',
                marginTop: smallScreen ? '10px' : '0',
                marginBottom: smallScreen ? '10px' : '0'
            },
            active: {
                fontWeight: 500,
                fontSize: '22px',
                color: '#473bf0',
                borderLeft: smallScreen ? 'none' : '4px solid #473bf0',
                lineHeight: '27px',
                cursor: 'pointer',
                marginTop: smallScreen ? '10px' : '0',
                marginBottom: smallScreen ? '10px' : '0'
            }
        }

        return (
            <div className="col" style={styles.column}>
                <nav className="nav" style={styles.nav}>
                    <div className="nav-link" href="#" style={ (activeCategory === -1) ? styles.active : styles.link } onClick={() => setCategory(-1)}>All</div>
                    {
                        categories.map((category, index) => (
                            <div key={index} className="nav-link" href="#" style={ (activeCategory === index) ? styles.active : styles.link } onClick={() => setCategory(index)}>{category}</div>
                        ))
                    }
                </nav>
            </div>
        )
    }

}

class ContentBox extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        const {smallScreen, content} = this.props

        const style = {
            marginTop: smallScreen ? '20px' : '60px',
            order: 3,
            textAlign: 'left'
        }

        return (
            <div className="content col" style={style}>
                {content}
            </div>
        )
    }

}

class TabBox extends Component {

    constructor(props) {
        super(props)
    }

    render() {
        const {tabId, setTab} = this.props

        const styles = {
            row: {
                justifyContent: 'center'
            },
            link: {
                fontSize: '16px',
                color: '#6a6a6a',
                borderBottom: '4px solid #e2e2ea',
                lineHeight: '27px',
                cursor: 'pointer'
            },
            active: {
                fontWeight: 500,
                fontSize: '22px',
                color: '#473bf0',
                borderBottom: '4px solid #473bf0',
                lineHeight: '27px',
                cursor: 'pointer'
            }
        }

        return (
            <div className="row" style={styles.row}>
                <nav className="nav">
                    <div className="nav-link" style={(tabId === 1) ? styles.active : styles.link} onClick={() => setTab(1)}>Companies</div>
                    <div className="nav-link" style={(tabId === 2) ? styles.active : styles.link} onClick={() => setTab(2)}>Solutions</div>
                </nav>
            </div>
        )
    }

}

class Box extends Component {

    constructor(props) {
        super(props)

        this.state = {
            hover: false,
            initialWidth: 0
        }

        this.myRef = React.createRef()
        this.imgRef = React.createRef()

        this.toggleHover = this.toggleHover.bind(this)
    }

    toggleHover() {
        const {hover, initialWidth} = this.state

        if (this.imgRef.current) {
            if (hover) {
                this.myRef.current.style.width = null
                this.myRef.current.style.height = null
                this.imgRef.current.style.width = `${initialWidth}px`
            }
            else {
                this.setState({
                    initialWidth: this.imgRef.current.clientWidth
                })

                this.myRef.current.style.width = `${this.myRef.current.clientWidth * 1.2}px`
                this.myRef.current.style.height = `${this.myRef.current.clientHeight * 1.2}px`
                this.imgRef.current.style.width = `${this.imgRef.current.clientWidth * 1.5}px`
            }
        }

        this.setState({
            hover: !hover
        })
    }

    render() {
        const {item, smallScreen, category, setContent} = this.props
        const {hover} = this.state

        const styles = {
            box: {
                height: '100px',
                width: '200px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                alignItems: 'center'
            },
            button: {
                backgroundColor: 'white',
                display: 'flex',
                padding: '15px',
                borderRadius: '12px',
                boxShadow: '0px 0px 20px rgba(71, 59, 240, 0.3)',
                justifyContent: 'center',
                alignItems: 'center',
                transition: 'all .3s ease-in-out',
                cursor: 'pointer'
            },
            hoverButton: {
                backgroundColor: 'red',
                display: 'flex',
                padding: '15px',
                borderRadius: '12px',
                boxShadow: '0px 0px 20px rgba(71, 59, 240, 0.3)',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#c4c1fb',
                opacity: 1,
                transition: 'all .3s ease-in-out',
                cursor: 'pointer'
            },
            img: {
                maxHeight: '50px',
                maxWidth: '150px',
                transition: 'all .3s ease-in-out'
            },
            hoverImg: {
                maxHeight: '50px',
                maxWidth: '150px',
                width: '130px',
                transition: 'all .3s ease-in-out'
            },
            inactive: {
                boxShadow: 'none',
                border: '1px solid #eeeeee'
            },
            invisible: {
                display: smallScreen ? 'none' : 'flex'
            }
        }

        return (
            <div className="compSolBox" style={(item.category === category || category === -1) ? styles.box : (smallScreen ? styles.invisible : styles.box)}>
                <div className="compSolButton"
                    ref={this.myRef}
                    style={hover ? styles.hoverButton : ((item.category === category || category === -1) ? styles.button : {...styles.button, ...styles.inactive})}
                    onMouseEnter={this.toggleHover}
                    onMouseLeave={this.toggleHover}
                    onClick={setContent}
                >
                    <span ref={this.imgRef} style={{width: '130px'}}><DynImage comp={item.image} style={styles.img} /></span>
                </div>
            </div>
        )
    }
}

const DynImage = ({ comp, ...rest }) => {
    if (comp === 'plankton') {
        return <img src={PlanktonComp} { ...rest } />;
    }
    else if (comp === 'gig') {
        return <img src={YougigComp} { ...rest } />;
    }
    else {
        const DImg = require(`../assets/img/compsol/${comp}_1.svg`);

        return <DImg { ...rest } />;
    }
}

const companies = [
    {
        name: 'Youradvisors',
        image: 'advisors',
        category: 4,
        content: (<><h3>Youradvisors</h3>
            <p>
                Conducting legal research can be really drudgy and takes a lot of time. Your Advisors' lawyers make it fast, easy, and affordable for you who need help taking your business to the next level. With Youradvisors, legal research and consultation can be fast and efficient through artificial intelligence being integrated into the platform.
            </p>
            <p>
                You can be confident in your research, make it fast, and comprehensive. Smart Contracts and AI-powered software is where the old meets the new and our professional and outrageous lawyers make the implementation even easier.
            </p>
            <button type="button" style={{ marginTop: '40px' }} className="btn btn-primary btn-lg" onClick={() => navigate('/youradvisors')}>See more</button>
        </>)
    },
    {
        name: 'Youmedico',
        image: 'medico',
        category: 0,
        content: (<><h3>Youmedico</h3>
            <p>
            With Youmedico, virtual medical care has become even more sophisticated and efficient. We have created a solution that you can fully configure and customise for your needs. With a fully customisable functionality, essential features, and possibilities, we enable you to practice medicine in a modern way.
            </p>
            <button type="button" style={{ marginTop: '40px' }} className="btn btn-primary btn-lg" onClick={() => navigate('/youmedico')}>See more</button>
            </>)
    },
    {
        name: 'Youcloud',
        image: 'cloud',
        category: 3,
        content: (<><h3>Youcloud</h3>
            <p>
            Data is the most important asset for any company, and thus we have set our values on having Your content safe and sound, as well as accessible. Youcloud provides solutions for keeping the data of your business in trustworthy GDPR compliance.</p>
            <p>
                With the most advanced approach and the data storage and usage process, Youcloud has come up with the leading features, to make your business and workflow more efficient, through services such as unlimited and easy teleconferencing, private and secure chat, website hosting, and suite, which gives you ability to store and share all your documents and information in one place.
            </p>
                <button type="button" style={{ marginTop: '40px' }} className="btn btn-primary btn-lg" onClick={() => navigate('/youcloud')}>See more</button>
            </>)
    },
    {
        name: 'Uteckie',
        image: 'uteckie',
        category: 5,
        content: (<><h3>Uteckie</h3>
            <p>
            Uteckie news portal introduces you to the most recent news in the tech industries. With us, you can discover top tendencies, challanges and development of different tech-related fields.</p>
                <button type="button" style={{ marginTop: '40px' }} className="btn btn-primary btn-lg" onClick={() => navigate('/uteckie')}>See more</button>
            </>)
    },
    {
        name: 'Plankton',
        image: 'plankton',
        category: 1,
        content: (<><h3 className="internalNarrowCol">
                we’re plankton. <br />a creative ecosystem.
            </h3>
            <p className="internalNarrowCol">
                we’re the creators of ideas and the builders of concepts. <br />
                branding & PR, marketing & social. <br />
                business development. <br />
                we will not only disturb you, we will shatter your entire
                existence. <br />
                welcome.
            </p>
                <button type="button" style={{ marginTop: '40px' }} className="btn btn-primary btn-lg" onClick={() => navigate('/plankton')}>See more</button>
            </>)
    },
    {
        name: 'Youdata',
        image: 'data',
        category: 1,
        content: (<><h3>Youdata</h3>
            <p>
            Every action or interaction produces information. We live to write our own story. Every person and every business has its own narrative to tell, and while you are authors of your story, we will be the bookkeepers of your data.
            </p>
                <button type="button" style={{ marginTop: '40px' }} className="btn btn-primary btn-lg" onClick={() => navigate('/youdata')}>See more</button>
            </>)
    },
    {
        name: 'Yougig',
        image: 'gig',
        category: 2,
        content: (<><h3>Yougig</h3>
            <p>
            Finding the right professional for your business is just as equally important as finding the right business for your talent. Yougig can provide useful insights to the company, while with the most advanced AI technology it can also make the perfect pick of a talent for your business.
            </p>
            <p>
                Either a large enterprise, a small business, or a talent, regardless of the place and location, we will create the bridge for you!
            </p>
                <button type="button" style={{ marginTop: '40px' }} className="btn btn-primary btn-lg" onClick={() => navigate('/yougig')}>See more</button>
            </>)
    }
];

const solutions = [
    /*
    {
        name: 'Global',
        category: 0,
        image: GlobalLogo,
        content: (<>
            <h3>Global</h3>
            <p>With Youmedico the virtual medical care has become even more sophisticated and more efficient. With the integrated white-label software the platform can provide all the essential features and functions, necessary for the virtual medical practice. The most modern approach towards telemedicine, Youmedico guarantees the full virtualization of your clinic, and the most important part is the smooth transferring process.</p>
            <button type="button" style={{ marginTop: '40px' }} className="btn btn-primary btn-lg" onClick={() => navigate('/products/youmedico')}>See more</button>
        </>)
    },
    {
        name: 'RPM',
        category: 0,
        image: RPMLogo,
        content: (<>
            <h3>RPM</h3>
            <p>With Youmedico the virtual medical care has become even more sophisticated and more efficient. With the integrated white-label software the platform can provide all the essential features and functions, necessary for the virtual medical practice. The most modern approach towards telemedicine, Youmedico guarantees the full virtualization of your clinic, and the most important part is the smooth transferring process.</p>
            <button type="button" style={{ marginTop: '40px' }} className="btn btn-primary btn-lg" onClick={() => navigate('/products/youmedico')}>See more</button>
        </>)
    },
    {
        name: 'Telemedicine',
        category: 0,
        image: TelemedicineLogo,
        content: (<>
            <h3>Telemedicine</h3>
            <p>With Youmedico the virtual medical care has become even more sophisticated and more efficient. With the integrated white-label software the platform can provide all the essential features and functions, necessary for the virtual medical practice. The most modern approach towards telemedicine, Youmedico guarantees the full virtualization of your clinic, and the most important part is the smooth transferring process.</p>
            <button type="button" style={{ marginTop: '40px' }} className="btn btn-primary btn-lg" onClick={() => navigate('/products/youmedico')}>See more</button>
        </>)
    },
    */
    {
        name: 'Yourvault',
        category: 2,
        image: 'vault',
        content: (<>
            <h3>Yourvault</h3>
            <p>Get your notes in various spreadsheets heaped up organised. Our intuitive and feature-rich asset management system will bring all of your IT assets in front of your eyes. Thanks to it, you’ll be able to increase efficiency, utilize assets better, and diminish your costs.</p>
            <button type="button" style={{ marginTop: '40px' }} className="btn btn-primary btn-lg" onClick={() => navigate('/products/yourvault')}>See more</button>
        </>)
    },
    {
        name: 'Youchat',
        category: 5,
        image: 'chat',
        content: (<>
            <h3>Youchat</h3>
            <p>In the era of digital transformation and advancement, communication became the main tool for development and adapting. Communication has always been of vital importance and methods of communication have been evolving throughout the centuries.</p>
            <button type="button" style={{ marginTop: '40px' }} className="btn btn-primary btn-lg" onClick={() => navigate('/products/youchat')}>See more</button>
        </>)
    },
    {
        name: 'YouCRM',
        category: 1,
        image: 'crm',
        content: (<>
            <h3>YouCRM</h3>
            <p>If you've thousands of customers, you probably either don't engage with them or have many employees to keep it organised. With YouCRM, you only need a few customer service representatives to satisfy thousands of customers' queries actively. Integration options help to gather data from all your sources. Contact management segments them into categories making usage of data easier. Lead scoring and prioritization automate moving potential customers across your sales funnel.</p>
            <button type="button" style={{ marginTop: '40px' }} className="btn btn-primary btn-lg" onClick={() => navigate('/products/youcrm')}>See more</button>
        </>)
    },
    {
        name: 'Youfolio',
        category: 2,
        image: 'folio',
        content: (<>
            <h3>Youfolio</h3>
            <p>Youfolio digital asset management is a tool for storing, sharing, and organising digital assets in one place. It's one of the must-have tools in your digital marketing environment. Gathering all your images, videos, and other files let you manage them efficiently and get the maximum value from them.</p>
            <button type="button" style={{ marginTop: '40px' }} className="btn btn-primary btn-lg" onClick={() => navigate('/products/youcrm')}>See more</button>
        </>)
    },
    {
        name: 'Youdocs',
        category: 3,
        image: 'docks',
        content: (<>
            <h3>Youdocs</h3>
            <p>Youdocs is the Wikipedia for your document, it is tutorial for you and the relief for the writer. With Youdocs you can easily access most of the data regarding the right creation of your document. The support and the tips are guaranteed from Youdocs, with the potential to become your best friend in writing.</p>
            <button type="button" style={{ marginTop: '40px' }} className="btn btn-primary btn-lg" onClick={() => navigate('/products/youdocs')}>See more</button>
        </>)
    },
    {
        name: 'Youmauto',
        category: 1,
        image: 'mauto',
        content: (<>
            <h3>Youmauto</h3>
            <p>There is an old saying in the marketing industry: "The advertising budget of which results you can't measure is wasted." Digital marketing has been prevailing over traditional marketing recently because of its extensive tools and features that provide advanced analysis opportunities.</p>
            <p>Now, marketing automation is your digital marketing assistant with the potential of replacing tens of employees, thus shrinking your fixed costs. Interested in more? You should be!</p>
            <button type="button" style={{ marginTop: '40px' }} className="btn btn-primary btn-lg" onClick={() => navigate('/products/youmauto')}>See more</button>
        </>)
    },
    {
        name: 'Youmeet',
        category: 5,
        image: 'meet',
        content: (<>
            <h3>Youmeet</h3>
            <p>The virtual world is increasing and is evolving all the way through. The wind of changes has blown in our direction, and we have to adapt to the new changes and challenges. While we might not always be able to meet up, you can always have a virtual meeting, and the distance should never be the barrier. With Youmeet, you can hold a conference, you can call your friends or colleagues on the internet. Videoconference is fully supported by the audio, dial-in, recording, and simulcasting features.</p>
            <button type="button" style={{ marginTop: '40px' }} className="btn btn-primary btn-lg" onClick={() => navigate('/products/youmeet')}>See more</button>
        </>)
    },
    {
        name: 'Youuni',
        category: 4,
        image: 'uni',
        content: (<>
            <h3>Youuni</h3>
            <p>There is an old saying in the marketing industry: "The advertising budget of which results you can't measure is wasted." Digital marketing has been prevailing over traditional marketing recently because of its extensive tools and features that provide advanced analysis opportunities.</p>
            <p>Now, marketing automation is your digital marketing assistant with the potential of replacing tens of employees, thus shrinking your fixed costs. Interested in more? You should be!</p>
            <button type="button" style={{ marginTop: '40px' }} className="btn btn-primary btn-lg" onClick={() => navigate('/products/youuni')}>See more</button>
        </>)
    },
    {
        name: 'Youoptimize',
        category: 1,
        image: 'optimize',
        content: (<>
            <h3>Youoptimize</h3>
            <p>Supply chain management is your business. It involves every small and big details from the acquisition of raw materials to delivering the final products to the end customers.</p>
            <p>Our inventory planning solutions are at your service to calculate safety stocks, redefine quantity, and frequency settings.</p>
            <p>Creating plans and schedules based on your company’s limits, such as team members, available materials, and resources, is now easier than ever with our production planning solutions.</p>
            <button type="button" style={{ marginTop: '40px' }} className="btn btn-primary btn-lg" onClick={() => navigate('/products/youoptimize')}>See more</button>
        </>)
    },
    {
        name: 'YouSuite',
        category: 3,
        image: 'suite',
        content: (<>
            <h3>Yousuite</h3>
            <p>The possibilities of the internet capabilities are increasing day by day. With the increasing number of options, it is harder and harder to make everything organised and controlled. With the support of different Web and applications, we adapt to all the new possibilities and open rooms. While some of them might really be great solutions for our business, we might simply get lost on the way and our work might no longer be efficient enough at some point.</p>
            <button type="button" style={{ marginTop: '40px' }} className="btn btn-primary btn-lg" onClick={() => navigate('/products/yousuite')}>See more</button>
        </>)
    },
    {
        name: 'YouPage',
        category: 6,
        image: 'page',
        content: (<>
            <h3>Youpage</h3>
            <p>Improve your web appearance with our enterprise website builder. Don’t worry if you’re not a programmer or developer. The user interface and navigation of our solutions are built according to the users’ needs.</p>
            <p>You won’t need to write even a single line of code to launch your website. Just create your content and pages, and you’re ready to go!</p>
            <button type="button" style={{ marginTop: '40px' }} className="btn btn-primary btn-lg" onClick={() => navigate('/products/youpage')}>See more</button>
        </>)
    }
]

class CompSol extends Component {

    constructor() {
        super()

        this.defaultContent = (
			<>
				<h4>Youpal Group</h4>
				<p>Youpal Group owns multiple companies operating in different sectors. Select a company to learn more about it. Stay tuned, as our roadmap is in constant evolution!</p>
					<button type="button" style={{ marginTop: '40px' }} className="btn btn-primary btn-lg" onClick={() => navigate('/about-us')}>See more</button>
            </>
        )

        this.state = {
            smallScreen: false,
            activeCategory: -1,
            activeContent: this.defaultContent,
            activeTab: 1,
            companiesCategories: [
                'Healthcare',
                'Marketing',
                'Assets',
                'Cloud',
                'Legal',
                'News'
            ],
            solutionsCategories: [
                'Healthcare',
                'Marketing',
                'Assets',
                'Cloud',
                'Communication',
                'Web'
            ]
        }

        this.setCategory = this.setCategory.bind(this)
        this.setCompanyContent = this.setCompanyContent.bind(this)
        this.setTab = this.setTab.bind(this)
        this.resize = this.resize.bind(this)
    }

    setCategory(category) {
        this.setState({
            activeCategory: category
        })
    }

    setCompanyContent(id) {
        if (id >= 0) {
            this.setState({
                activeContent: companies[id].content
            })
        }
        else {
            this.setState({
                activeContent: this.defaultContent
            })
        }
    }

    setSolutionContent(id) {
        if (id >= 0) {
            this.setState({
                activeContent: solutions[id].content
            })
        }
        else {
            this.setState({
                activeContent: this.defaultContent
            })
        }
    }

    setTab(id) {
        this.setState({
            activeTab: id
        })
    }

    resize() {
        if (typeof window !== 'undefined') {
            this.setState({
                smallScreen: window.innerWidth < 1200
            })
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', this.resize)
            this.resize()
        }
    }

    componentWillUnmount() {
        if (typeof window !== 'undefined') window.removeEventListener('resize', this.resize)
    }

    render() {
        const {smallScreen, activeCategory, activeContent, activeTab, companiesCategories, solutionsCategories} = this.state

        const styles = {
            compSol: {
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                flexWrap: 'wrap',
                flexDirection: smallScreen ? 'column' : 'row',
                marginTop: smallScreen ? '30px' : '0' 
            },
            megaBox: {
                order: smallScreen ? '1' : 2
            },
            borderBox: {
                position: 'absolute',
                zIndex: -1,
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                paddingTop: '40px',
                paddingBottom: '40px',
                display: smallScreen ? 'none' : 'flex'
            },
            border: {
                border: '20px solid rgba(71, 59, 240, 0.24)',
                borderRadius: '50px',
                width: '50%'
            },
            boxes: {
                marginTop: '30px',
                position: 'relative',
                display: 'flex',
                flexDirection: smallScreen ? 'row' : 'column',
                overflowX: smallScreen ? 'auto' : 'unset'
            },
            row: {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                marginBottom: smallScreen ? '30px' : '0',
                marginTop: smallScreen ? '30px' : '0'
            },
            firstRow: {
                justifyContent: 'center'
            }
        }


        let items = []

        if (activeTab === 1) {
            for (let i = 0; i < companies.length; i = i + 2) {
                items.push(
                    <div key={i} className="compSolRow" style={(i === 0) ? {...styles.row, ...styles.firstRow} : styles.row}>
                        <Box smallScreen={smallScreen} item={companies[i]} category={activeCategory} setContent={() => this.setCompanyContent(i)} />
                        { companies[i + 1] ?
                            <Box smallScreen={smallScreen} item={companies[i + 1]} category={activeCategory} setContent={() => this.setCompanyContent(i + 1)} />
                        : ''}
                    </div>
                )
            }
        }
        else {
            for (let i = 0; i < solutions.length; i = i + 2) {
                items.push(
                    <div key={i} className="compSolRow" style={(i === 0 || !solutions[i + 2]) ? {...styles.row, ...styles.firstRow} : styles.row}>
                        <Box smallScreen={smallScreen} item={solutions[i]} category={activeCategory} setContent={() => this.setSolutionContent(i)} />
                        { solutions[i + 1] ?
                            <Box smallScreen={smallScreen} item={solutions[i + 1]} category={activeCategory} setContent={() => this.setSolutionContent(i + 1)} />
                        : ''}
                    </div>
                )
            }
        }

        return (
            <div className="compSol" style={styles.compSol}>
                <Navigation smallScreen={smallScreen} categories={(activeTab === 1) ? companiesCategories : solutionsCategories} activeCategory={activeCategory} setCategory={this.setCategory} />
                <div className="col" style={styles.megaBox}>
                    <TabBox tabId={activeTab} setTab={this.setTab} />
                    <div className="compSolBoxes" style={styles.boxes}>
                        <div className="compSolBorderBox" style={styles.borderBox}>
                            <div className="compSolBorder" style={styles.border}></div>
                        </div>
                        {items}
                    </div>
                </div>
                <ContentBox smallScreen={smallScreen} content={activeContent} />
            </div>
        )
    }

}

export default CompSol